let body = document.getElementsByTagName("body")[0];
let toggleMenu = document.getElementsByClassName('js-toggle-menu')[0];
let menu = document.getElementsByClassName('js-menu')[0];

toggleMenu.addEventListener('click', function() {
  if (menu.classList.contains('d-block')) {
    body.classList.remove('overflow-hidden');
    toggleMenu.classList.remove('open');
    menu.classList.remove('d-block');
  } else {
    body.classList.add('overflow-hidden');
    toggleMenu.classList.add('open');
    menu.classList.add('d-block');
  }
});