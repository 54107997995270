document.onreadystatechange = function () {
    if (document.readyState == "interactive" && document.querySelector('.show-more-less')) {

        let toggleDivs = document.querySelectorAll('.property-summary').forEach(function(el) {

            if(el.querySelector('.show-more-less-inner').clientHeight > el.querySelector('.show-more-less').clientHeight) {
                el.querySelector('.show-more-text').style.display = 'block';
            }
            el.querySelector('.show-more-text').addEventListener('click', function() {
                this.style.display = 'none';
                el.querySelector('.show-less-text').style.display = 'block';
                el.querySelector('.show-more-less').classList.add('full');
            });
            el.querySelector('.show-less-text').addEventListener('click', function() {
                this.style.display = 'none';
                el.querySelector('.show-more-text').style.display = 'block';
                el.querySelector('.show-more-less').classList.remove('full');
                el.scrollIntoView({
                    behavior: "smooth",
                    block: 'start'
                });
            });
        });
    }
}
