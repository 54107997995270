import * as $ from "jquery";
import * as url from "url";

global.$ = global.jQuery = $;
window.Popper = require('popper.js');

require('bootstrap');
require('./mobile-menu');
require('./glider');
require('./show-more-less');
require('./caravans');
//require('./scroll-to');